<!-- 设备管理-维修管理-维修计划-添加计划 -->
<template>
    <div class="page_container height_100"  v-loading="pageLoad">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center bgc_FFFFFF_height">
            <div class="box_head1 width_100">设备信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                     <el-input v-model="formInline.deviceTypeName" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                        @click="changeDevice">
                        选择设备</el-button>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">基本信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="维修班组：">
                    <el-select v-model="formInline.execGroup" placeholder="请选择班组" class="width_180" clearable>
                        <el-option v-for="(item,index) in ExecGroup" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：" class=" ">
                    <el-select v-model="formInline.urgenLevel" placeholder="请选择紧急程度" class="width_180" clearable>
                        <el-option v-for="(item,index) in UrgenLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修人：" class=" ">
                    <el-select v-model="formInline.execPerson" placeholder="请选择维修人" class="width_180" multiple clearable
                        collapse-tags>
                        <el-option v-for="(item,index) in personList" :key="index" :label="item.userName"
                            :value="item.userName">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修类型：" class=" ">
                    <el-select v-model="formInline.execType" placeholder="请选择维修类型" class="width_180" clearable>
                        <el-option v-for="(item,index) in Category" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="计划时间：" class=" ">
                    <el-date-picker value-format="timestamp" v-model="formInline.planTime" prefix-icon="el-icon-date" clear-icon="el-input-icon"
                        type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item> -->
                <!-- <el-form-item label="提醒时间：" class=" ">
                    <el-date-picker value-format="timestamp" v-model="formInline.remindTime" prefix-icon="el-icon-date"
                        clear-icon="el-input-icon" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.remarks" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <el-dialog title="设备选择" :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <div class="flex flex_column height_370 mg_top_10">
                    <div class="flex">
                        <el-cascader size="medium" v-model="organizationValue" placeholder="选择设备" :options="organization"
                            ref="demoCascader" :props="defaultProps" class="el_cascader_width " @change="handleChangeCascader"
                            :show-all-levels="false" collapse-tags filterable></el-cascader>
                        <el-button type="primary" class="mg_left_10" size="medium" @click="queryDevice">查询</el-button>
                    </div>
                    <el-table :data="equInfoList" style="width: 100%" class="mg_top_20" height="100%" v-loading="load">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="EquipmentID" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentName" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentModel" label="规格型号" width="">
                        </el-table-column>
                          <el-table-column prop="EquipmentTypeName" label="设备类型" width="">
                        </el-table-column>
                        <el-table-column prop="WorkshopSection" label="所属部门" width="">
                        </el-table-column>
                        <el-table-column prop="InstallationPosition" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="choiceEquipment(scope.row)">选择</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </el-dialog>


            <div class="flex mg_top_20 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        repairGroupQuery,
        repairUrgenLevel,
        repairPlanStatusQuery,
        repairTypeQuery,
        // maintainPlanLoopUnitQuery,
        repairPlanInsert,
        repairExecPersonQuery,
    } from "@/api/api_deviceManagement"
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                ExecGroup: [],
                UrgenLevel: [],
                planStatusAr: [],
                Category: [],
                loopUnitQuery: [],
                dialogTableVisible: false,
                radio1: 1,
                formInline: {},
                form: {},
                value: '',
                formLabelWidth: '110px',
                organizationValue: '',
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],

                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                personList: [],
                userID:'',

                load:false,
                pageLoad:true,
                companyType:{},
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.equipmentLedgerOrganizationSelect();
            this.repairGroupQuery();
            this.repairUrgenLevel();
            this.repairPlanStatusQuery();
            this.repairTypeQuery();
            this.repairExecPersonQuery()
            // this.maintainPlanLoopUnitQuery()
        },
        methods: {
            async repairGroupQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairGroupQuery(param);
                this.ExecGroup = selectRes.data;
            },
            async repairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.UrgenLevel = selectRes.data;
            },
            async repairPlanStatusQuery() {
                const selectRes = await repairPlanStatusQuery();
                this.planStatusAr = selectRes.data;
            },
            async repairExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairExecPersonQuery(param);
                this.personList = selectRes.array;
            },

            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.Category = selectRes.data
            },
            // async maintainPlanLoopUnitQuery() {
            //     const selectRes = await maintainPlanLoopUnitQuery();
            //     this.loopUnitQuery = selectRes.rows
            // },
            delCurrentEqu() {
                this.$confirm('此操作将删除该设备, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.formInline.deviceID = row.EquipmentID.toString();
                    this.formInline.deviceName = row.EquipmentName;
                    this.formInline.deviceType = row.EquipmentType.toString();
                    this.formInline.deviceModel = row.EquipmentModel;
                    this.formInline.deviceDepartment = row.Department;
                    this.formInline.deviceLocation = row.InstallationPosition;
                }).catch(() => {});

            },
            changeDevice() {
                this.equInfoList = [];
                this.pageQuantity = 0;
                this.equipmentLedgerQuery();
                // this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                this.load = true;
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.companyType = selectRes.data.organizationPermissionAll.filter(r=>r.Type=="C")[0];//type为c的organization
                this.checkData = this.companyType;
                this.pageLoad = false;
                // this.equipmentLedgerQuery();
            },
            queryDevice() {
                // console.log(this.checkData)
                this.currentPage = 1;
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                this.load = true;
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
                this.load = false;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.currentEqu = [];
                    this.formInline.deviceID = row.EquipmentID.toString();
                    this.formInline.deviceName = row.EquipmentName;
                    this.formInline.deviceType = row.EquipmentType.toString();
                    this.formInline.deviceModel = row.EquipmentModel;
                    this.formInline.deviceDepartment = row.WorkshopSection;
                    this.formInline.deviceLocation = row.InstallationPosition;
                    this.formInline.deviceTypeName = row.EquipmentTypeName;
                    this.dialogTableVisible = false;
                    // this.currentEqu.push(row);
                    // console.log(this.currentEqu)

                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            handleChangeCascader() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.companyType;
                    return
                }
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                // console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            sbumit() {
                // console.log("fm", this.formInline)
                // console.log("equ", this.currentEqu)
                // this.formInline.deviceID = this.currentEqu[0].EquipmentID.toString();
                // this.formInline.deviceName = this.currentEqu[0].EquipmentName;
                // this.formInline.deviceType = this.currentEqu[0].EquipmentType;
                // this.formInline.deviceModel = this.currentEqu[0].EquipmentModel;
                // this.formInline.deviceDepartment = this.currentEqu[0].Department;
                // this.formInline.deviceLocation = this.currentEqu[0].InstallationPosition;
                //    <el-table-column prop="equipmentID" label="设备编号" width="">
                //     </el-table-column>
                //     <el-table-column prop="EquipmentName" label="设备名称" width="">
                //     </el-table-column>
                //     <el-table-column prop="EquipmentModel" label="规格型号" width="">
                //     </el-table-column>
                //     <el-table-column prop="EquipmentType" label="设备类型" width="">
                //     </el-table-column>
                //     <el-table-column prop="Department" label="使用部门" width="">
                //     </el-table-column>
                if (!this.formInline.deviceID) {
                    this.$message({
                        message: "请选择设备",
                        type: 'warning'
                    })
                    return
                }
                this.repairPlanInsert()
            },
            async repairPlanInsert() {
                this.formInline.userID = this.userID;
                delete this.formInline.deviceTypeName;
                this.formInline.execPerson = this.formInline.execPerson.toString()
                console.log(this.formInline.execPerson);
                const selectRes = await repairPlanInsert(this.formInline);

                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'error'
                    });
                }

            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>